/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

import adminBarHtml from './html-const';
import translation from './lang/en.json';

const attachToDocumentBody = (adminBarElm: HTMLDivElement) => {
    const body: HTMLBodyElement | null = document.querySelector('body');
    const closeButton: HTMLDivElement | null = adminBarElm.querySelector('#close-admin-bar');

    body?.classList.add('hasAdminBar');
    body?.insertBefore(adminBarElm, body.firstChild);

    closeButton?.addEventListener('click', () => {
       body?.classList.remove('hasAdminBar');
       body?.querySelectorAll('.adminBar').forEach(adminBar => {
           adminBar.remove();
       });

       document.cookie = 'ADMIN_BAR=0; path=/';
    });
};

const createAdminBar = (): HTMLDivElement => {
    const elm = document.createElement('div');
    elm.classList.add('adminBar');

    return elm;
};

const AdminBar = (
  secureBaseUrl: string,
  channelId = 1,
  maintenanceMode: string,
  productId: string,
  categoryId: string
): void => {
    const lang = translation;
    const { header, password } = JSON.parse(maintenanceMode) || {};
    const isMaintenanceMode = header && !password;

    const isInIframe = () => {
        try {
            return window.location !== window.parent.location;
        } catch (e) {
            return true;
        }
    };

    const getContextualMatch = (_productId: string, _categoryId: string) => {
        if (_productId && parseInt(_productId, 10)) {
            return {
                type: 'product',
                url: `/manage/products/${_productId}/edit`,
            };
        } else if (_categoryId && parseInt(_categoryId, 10)) {
            return {
                type: 'category',
                url: `/manage/products/categories/${_categoryId}/edit`,
            };
        }
    };

    const doesNotHaveAdminCookie = () => document.cookie.indexOf('ADMIN_BAR=1') === -1;

    // Set admin cookie if appropriate
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (window.URLSearchParams && window.URL && (new URL(document.location as any)).searchParams.get('ctk')) {
        document.cookie = 'ADMIN_BAR=1; path=/';
    }

    // Return if isInIframe is true (inside page builder) or if there is no admin cookie
    if (doesNotHaveAdminCookie() || isInIframe()) {
        return;
    }

    const contextualMatch = getContextualMatch(productId, categoryId);
    const url = encodeURIComponent(
        (new URL(window.location.href).pathname + window.location.search)
            .replace(/^\/|\/$/g, '')
            .replace(/[&|?]?ctk=[a-z|0-9|-]+&?/g, '')
    );

    const adminBarElm: HTMLDivElement = createAdminBar();
    adminBarElm.classList.add('storefront', 'adminBar');

    adminBarElm.insertAdjacentHTML('afterbegin', adminBarHtml(
        {
        channelId,
        contextualMatch,
        isMaintenanceMode,
        lang,
        password,
        secureBaseUrl,
        url,
    }
));

    attachToDocumentBody(adminBarElm);
};

export default AdminBar;
