/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access */
import adminBrush from './img/admin-brush.svg';
import adminClose from './img/admin-close.svg';
import adminEdit from './img/admin-edit.svg';
import adminLink from './img/admin-link.svg';
import adminToolTip from './img/admin-tool-tip.svg';
import logo from './img/logo-small.svg';

interface AdminBarHtmlProps {
    channelId: number;
    contextualMatch: {
        type: string;
        url: string;
    } | undefined;
    isMaintenanceMode: boolean;
    lang: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    password: string;
    secureBaseUrl: string;
    url: string;
}

// eslint-ignore
const adminBarHtml = ({
    channelId,
    contextualMatch,
    isMaintenanceMode,
    lang,
    password,
    secureBaseUrl,
    url,
}: AdminBarHtmlProps): string => `
    <div class="adminBar-logo">
        <a href="${secureBaseUrl}/manage/dashboard">
            <img src="${logo}" width="45"/>
        </a>
    </div>
        <div class="adminBar-content">
        ${password ?
        `<div class="adminBar-private">
            <span class="preview">${lang.admin.prelaunch_header} <strong>${password}</strong></span>
        </div>` : ''}
        ${isMaintenanceMode ?
        `<div class="adminBar-private">
            <span>${lang.admin.maintenance_header}</span>
            <span class="svg-icon svg-baseline adminBar-large tooltip">
                <img src="${adminToolTip}"/>
                <span class="tooltiptext tooltip-bottom">${lang.admin.maintenance_tooltip}</span>
            </span>
            <a href="?showStore=no" class="adminBar-large">${lang.admin.maintenance_showstore_link} 
                <span class="svg-icon svg-baseline">
                    <img src="${adminLink}" />
                </span>
            </a>
         </div>` : ''}
         <div class="adminBar-links">
            ${contextualMatch ? `<a href='${secureBaseUrl}${contextualMatch.url}' target="_blank">
                <span class="svg-icon svg-baseline">
                    <img src="${adminEdit}"/>
                </span><span class="adminBar-large"> Edit ${contextualMatch.type} information</span></a>` : ''}
            <a href="${secureBaseUrl}/manage/page-builder?channelId=${channelId}${url ? `&redirectIframeUrl=${url}` : ''}" target="_blank">
                <span class="svg-icon svg-baseline">
                    <img src="${adminBrush}"/>
                </span><span class="adminBar-large"> ${lang.admin.page_builder_link}</span>
            </a>
         </div>
         <div class="adminBar-close" id="close-admin-bar">
            <span><img src="${adminClose}" width="14" alt="Close Admin Bar"/></span>
         </div>
    </div>`;

export default adminBarHtml;
