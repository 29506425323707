import AdminBar from './admin-bar';
import './sass/admin-bar.scss';

declare global {
    interface Window {
        bcAdminBar: any // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

window.bcAdminBar = AdminBar;
